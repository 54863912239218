import request from "@/utils/request";

// 登录
export function login(data) {
  return request({
    url: "/login",
    method: "POST",
    data: data
  });
}

// 获取用户详细信息
export function getInfo() {
  return request({
    url: "/bi/info",
    method: "GET"
  });
}

// 获取区域
export function areaSelect(data) {
  return request({
    url: "/bi/area" + data,
    method: "GET"
  });
}

// 退出方法
export function logout() {
  return request({
    url: "/logout",
    method: "GET"
  });
}

<template>
  <header class="header">
    <div class="flex">
      <div class="header-left">
        <img src="@/assets/images/login/logo2.png" v-if="singer" class="logo" />
        <img src="@/assets/images/header/logo.png" v-else class="logo" />
        <div><i class="icon el-icon-timer"></i>{{ time }}</div>
      </div>
      <div class="title">{{ title }}</div>
      <div class="header-right">
        <!-- <div class="user">{{ userinfo }}</div> -->
        <el-cascader
          ref="cascader"
          :props="addressProps"
          :show-all-levels="false"
          size="small"
          clearable
          @change="handleChange"
          v-if="$route.name == 'Index'"
          class="select"
        ></el-cascader>
        <div class="login-out" @click="logout">
          <i class="icon el-icon-switch-button"></i>退出
        </div>
      </div>
    </div>
  </header>
</template>
<script>
import dayjs from "dayjs";
import { getInfo, logout,areaSelect } from "@/api/login";

export default {
  data() {
    return {
      singer:'',
      timeInterval: null,
      time: dayjs().format("YYYY-MM-DD HH:mm:ss"),
      title: "餐厨垃圾智慧监管平台",
      userinfo: "管理员",
      addressProps: {
        lazy: true,
        checkStrictly: true,
        lazyLoad(node, resolve) {
          const { level } = node;
          let pcode = level > 0 ? "/" + node.value : "";
          areaSelect(pcode).then(response => {
            const nodes = response.data.map(item => ({
              value: item.areaCode,
              label: item.areaName,
              leaf: item.leaf,
              disabled: item.disabled
            }));
            resolve(nodes);
          });
        }
      },
      infoInterval: null
    };
  },
  mounted() {
    let singer= sessionStorage.getItem('singer')
    this.singer=singer || null
    this.getInfo();
    this.timeInterval = setInterval(() => {
      this.refreshTime();
    }, 1000);
    this.infoInterval = setInterval(() => {
      this.getInfo();
    }, 1000 * 60 * 30);
  },
  beforeDestroy() {
    clearInterval(this.timeInterval);
    clearInterval(this.infoInterval);
  },
  methods: {
    // 动态时间
    refreshTime() {
      this.time = dayjs().format("YYYY-MM-DD HH:mm:ss");
    },
    // 用户信息
    getInfo() {
      getInfo().then(res => {
        this.title = res.data.platName;
        this.userinfo = res.data.name;
      });
    },
    handleChange(value) {
      if (this.$refs.cascader) {
        this.$refs.cascader.dropDownVisible = false;
      }
      this.$store.commit("SAVE_AREACODE", value[value.length - 1]);
    },
    logout() {

      logout().then(() => {
      
        if(sessionStorage.getItem('singer')){
          window.opener = null
          window.open("about:blank", "_top").close()
        }else{
          window.sessionStorage.clear();
           this.$router.push("/login");
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  height: 7.407vh;
  background: url("../../assets/images/header/head_bg.png") no-repeat;
  background-size: 100% 100%;
  margin-bottom: 0.926vh;
  color: rgba(172, 237, 253, 1);
  .flex {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 100%;
    position: relative;
  }
  .title {
    font-size: 2.25rem;
    letter-spacing: 4px;
    font-weight: 500;
    font-family: "黑体";
    background: linear-gradient(0deg, #b5d7ea 0%, #ffffff 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .header-left {
    position: absolute;
    left: 1.042vw;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 5.556vh;
    line-height: 5.556vh;
    width: 26%;
    .logo {
      margin-right: 10px;
      // width: 200px;
      // height: 5.55vh;
    }
  }
  .header-right {
    position: absolute;
    right: 1.042vw;
    top: 0;
    display: flex;
    align-items: center;
    height: 5.556vh;
    line-height: 5.556vh;
    width: 26%;
    .user {
      margin-right: 1.042vw;
      font-size: 1.125rem;
    }
    .select {
      margin-right: 0.521vw;
      width: 9vw;
    }
    .login-out {
      position: absolute;
      right: 0;
      cursor: pointer;
      &:hover {
        color: rgb(228, 222, 222);
      }
    }
  }
  .icon {
    margin-right: 0.521vw;
  }
}
</style>

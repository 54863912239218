import Vue from "vue";
import VueRouter from "vue-router";
import layout from "../layout/index";
import store from "../store/index";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/login")
  },
  {
    path: "/",
    component: layout,
    redirect: "/index",
    children: [
      {
        path: "/index",
        name: "Index",
        meta: {
          title: "首页",
          auth: true
        },
        component: () => import("@/views/index/index")
      },
      {
        path: "/collectMap",
        name: "collectMap",
        meta: {
          title: "收运地图",
          auth: true
        },
        component: () => import("@/views/collectMap/index")
      },
      {
        path: "/compy",
        name: "compy",
        meta: {
          title: "企业",
          auth: true
        },
        component: () => import("@/views/index/compy")
      },
      {
        path: "/video",
        name: "video",
        meta: {
          title: "监控视频",
          auth: true
        },
        component: () => import("@/views/video/index")
      },
      {
        path: "/analyse",
        name: "Analyse",
        meta: {
          title: "智能分析",
          auth: true
        },
        component: () => import("@/views/analyse/index")
      },
      {
        path: "/statistical",
        name: "Statistical",
        meta: {
          title: "统计明细",
          auth: true
        },
        component: () => import("@/views/statistical/index"),
        redirect: "/those_businesses",
        children: [
          {
            path: "/those_businesses",
            name: "those_businesses",
            meta: {
              title: "商户统计",
              auth: true
            },
            component: () =>
              import("@/views/statistical/those_businesses/index")
          },
          {
            path: "/collector_statistics",
            name: "collector_statistics",
            meta: {
              title: "收运统计",
              auth: true
            },
            component: () =>
              import("@/views/statistical/collector_statistics/index")
          },
          {
            path: "/disposal",
            name: "disposal",
            meta: {
              title: "处置统计",
              auth: true
            },
            component: () => import("@/views/statistical/disposal/index")
          },
          {
            path: "/grease_statistics",
            name: "grease_statistics",
            meta: {
              title: "油脂统计",
              auth: true
            },
            component: () =>
              import("@/views/statistical/grease_statistics/index")
          },
          {
            path: "/solidslag_statistics",
            name: "solidslag_statistics",
            meta: {
              title: "固渣统计",
              auth: true
            },
            component: () =>
              import("@/views/statistical/solidslag_statistics/index")
          }
        ]
      },
      {
        path: "/TaiZhang",
        name: "TaiZhang",
        meta: {
          title: "监管台账",
          auth: true
        },
        component: () => import("@/views/TaiZhang/index")
      },
      {
        path: "/merchantsDetails",
        name: "merchantsDetails",
        meta: {
          title: "商户详情",
          auth: true
        },
        component: () => import("@/views/merchantsDetails/index")
      },
      {
        path: "/collDetails",
        name: "collDetails",
        meta: {
          title: "收运详情",
          auth: true
        },
        component: () => import("@/views/merchantsDetails/collector_details")
      }
    ]
  },
  {
    path: "/404",
    name: "404",
    component: () => import("@/views/404.vue")
  },
  {
    path: "*",
    redirect: "/404"
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  const token = store.state.token; //login.vue组件请求成功保存属性
  // const token = window.localStorage.getItem("token");
  // const token = "";
  // console.log(token);
  if (token != null) {
    //如果有就直接到首页咯
    next();
  } else {
    if (to.path == "/login") {
      //如果是登录页面路径，就直接next()
      next();
    } else {
      //不然就跳转到登录；
      next("login");
    }
  }
});

export default router;

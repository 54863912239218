/**
 * axios封装
 * 请求拦截、响应拦截、错误统一处理
 */
import axios from "axios";
import router from "../router";
import store from "../store/index";
import { Message } from "element-ui";

/**
 * 提示函数
 * 禁止点击蒙层、显示一秒后关闭
 */
const tip = msg => {
  Message({
    message: msg,
    duration: 2000,
    forbidClick: true
  });
};

/**
 * 跳转登录页
 * 携带当前页面路由，以期在登录页面完成登录后返回当前页面
 */
const toLogin = () => {
  router.replace({
    path: "/login",
    query: {
      redirect: router.currentRoute.fullPath
    }
  });
};

/**
 * 请求失败后的错误统一处理
 * @param {Number} status 请求失败的状态码
 */
const errorHandle = (status, other) => {
  // 状态码判断
  switch (status) {
    // 401: 未登录状态，跳转登录页
    case 401:
      toLogin();
      break;
    // 403 token过期
    // 清除token并跳转登录页
    case 403:
      tip("登录过期，请重新登录");
      sessionStorage.removeItem("token");
      setTimeout(() => {
        toLogin();
      }, 2000);
      break;
    // 404请求不存在
    case 404:
      tip("请求的资源不存在");
      break;
    default:
      console.log(other);
  }
};

// 创建axios实例
axios.defaults.headers["Content-Type"] = "application/json;charset=utf-8";

// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: process.env.VUE_APP_BASE_API,
  // 超时
  timeout: 5000
});

/**
 * 请求拦截器
 * 每次请求前，如果存在token则在请求头中携带token
 */

service.interceptors.request.use(
  config => {
    // console.warn("请求成功");
    const token = store.state.token;
    // 是否需要设置 token
    const isToken = (config.headers || {}).isToken === false;
    if (token && !isToken) {
      config.headers["Authorization"] = "Bearer " + token; // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    return config;
  },
  error => {
    Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  // 请求成功
  res => {
    
    if (res.status === 200) {
      if (res.data.code === 401) {
        Message.error({
          message: "登录状态已过期",
          center: true
        });
        sessionStorage.removeItem("token");
        setTimeout(() => {
          toLogin();
        }, 2000);
        return;
      } else {
        return Promise.resolve(res.data);
      }
    } else {
      return Promise.reject(res.data);
    }
  },
  // 请求失败
  error => {
    const { response } = error;
    if (response) {
      // console.log("这里出错了");
      // 请求已发出，但是不在2xx的范围
      errorHandle(response.status, response.data.message);
      return Promise.reject(response);
    } else {
      // 处理断网的情况
      // eg:请求超时或断网时，更新state的network状态
      // network状态在app.vue中控制着一个全局的断网提示组件的显示隐藏
      // 关于断网组件中的刷新重新获取数据，会在断网组件中说明
      if (!window.navigator.onLine) {
        // 备注注释：理解中
        // console.log("备注注释：理解中");
        store.commit("changeNetwork", false);
      } else {
        Message.error({
          message: error,
          center: true
        });
        return Promise.reject(error);
      }
    }
  }
);

export default service;

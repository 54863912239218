<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  mounted() {
    // this.handleScreen();
    // window.addEventListener("resize", this.handleScreen);
  },
  destroyed() {
    window.removeEventListener("resize", this.handleScreen);
  },
  methods: {
    handleScreen() {
      const vm = this;
      const m = vm.detectZoom();
      document.body.style.zoom = 100 / Number(m);
    },
    detectZoom() {
      let ratio = 0,
        screen = window.screen,
        ua = navigator.userAgent.toLowerCase();
      if (window.devicePixelRatio !== undefined) {
        ratio = window.devicePixelRatio;
      } else if (~ua.indexOf("msie")) {
        if (screen.deviceXDPI && screen.logicalXDPI) {
          ratio = screen.deviceXDPI / screen.logicalXDPI;
        }
      } else if (
        window.outerWidth !== undefined &&
        window.innerWidth !== undefined
      ) {
        ratio = window.outerWidth / window.innerWidth;
      }
      if (ratio) {
        ratio = Math.round(ratio * 100);
      }
      return ratio;
    }
  }
};
</script>

<style lang="scss">
// 公共样式
@import "~@/assets/style/base.css";
@import "~@/assets/style/element.css";
</style>

import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: sessionStorage.getItem("token"),
    areaCode: ""
    // userinfo: sessionStorage.getItem("userinfo")
  },
  mutations: {
    //存储token方法
    //设置token等于外部传递进来的值
    SAVE_TOKEN(state, token) {
      // 存入本地缓存
      sessionStorage.setItem("token", token);
      // 更新值
      state.token = token;
    },
    SAVE_AREACODE(state, areaCode) {
      // 存入本地缓存
      sessionStorage.setItem("areaCode", areaCode);
      // 更新值
      state.areaCode = areaCode;
    }
  },
  getters: {
    //获取token方法
    //判断是否有token,如果没有重新赋值，返回给state的token
    // getToken(state) {
    //   if (!state.token) {
    //     state.token = sessionStorage.getItem("token");
    //   }
    //   return state.token;
    // }
  },
  actions: {},
  modules: {}
});

import Vue from "vue";

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

import App from "./App.vue";
import store from "./store";
import router from "./router";

Vue.config.productionTip = false;

Vue.use(ElementUI);

import * as echarts from "echarts";
Vue.prototype.$echarts = echarts;

import dateInit from "./utils/date";
Vue.prototype.$dateInit = dateInit;

// import BaiduMap from "vue-baidu-map";
// Vue.use(BaiduMap, {
//   // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
//   ak: "O5omG5F0QavGts2DoXjRMrSPxfo19orr"
// });

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");

<template>
  <div class="layout">
    <div id="app-main">
      <Head />
      <div
        class="tab-nav"
        v-if="
          $route.name == 'Index' ||
            $route.name == 'Analyse' ||
            $route.name == 'collectMap' ||
            $route.name == 'video'
        "
      >
        <router-link to="/index" class="navItem">首页</router-link>
        <router-link to="/analyse" class="navItem">智能分析</router-link>
        <router-link to="/statistical" class="navItem">统计明细</router-link>
        <!-- <router-link to="/TaiZhang" class="navItem">监管台账</router-link> -->

        <router-link to="/collectMap" class="navItem">收运地图</router-link>
        <router-link to="/video" class="navItem">监控视屏</router-link>
      </div>
      <router-view />
    </div>
  </div>
</template>

<script>
import Head from "./components/head";
export default {
  components: { Head },
  data() {
    return {};
  }
};
</script>

<style lang="scss" scoped>
.layout,
#app-main {
  width: 100%;
  height: 100vh;
  position: relative;
}
.tab-nav {
  position: absolute;
  top: 8.333vh;
  left: 0;
  width: 100%;
  z-index: 1;
  text-align: center;
  .navItem {
    text-decoration: none;
    color: #fff;
    font-size: 1.25rem;
    text-align: center;
    width: 13.7704vh;
    height: 4.815vh;
    line-height: 4.815vh;
    display: inline-block;
    border-radius: 10px;
    margin: 0 10px;
    background: url("../assets/images/header/nav.png") no-repeat;
    background-size: 100% 100%;
  }
  .router-link-active {
    background: url("../assets/images/header/nav_active.png") no-repeat;
    background-size: 100% 100%;
  }
}
</style>

<style lang="scss">
.box-title {
  width: 160px;
  height: 50px;
  line-height: 50px;
  color: #30d8fc;
  text-align: center;
  background: url("../assets/images/title_bg.png") no-repeat;
  background-size: 100% 100%;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, -50%);
  font-size: 1.25rem;
}
.box {
  width: 100%;
  border-radius: 10px;
  border: 1px solid #27c3c9;
  background: rgba($color: #0b1633, $alpha: 0.8);
  box-shadow: 0 0 10px #004fb3 inset;
  position: relative;
  padding: 30px 20px 20px 20px;
}
.box-bottom {
  margin-bottom: 30px;
}
</style>
